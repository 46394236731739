
import { defineComponent, ref } from 'vue'
import { db } from '@/main'
import PhotoCard from '@/components/photos/PhotoCard.vue'
import firebase from 'firebase'

export default defineComponent({
  components: {
    PhotoCard
  },
  setup () {
    const File = ref([])
    const photo = ref({})
    const count = ref(0)
    return {
      photo, count, File
    }
  },
  created () {
    const dbRef = db.collection('good').doc(this.$route.params.id)
    dbRef.get().then((doc) => {
      this.photo = doc.data()
    }).catch((error) => {
      this.$swal({
        title: 'Помилка...',
        text: error
      })
    })
  },
  methods: {
    previewFiles (event) {
      // process your files, read as DataUrl or upload...
      this.File = event.target.files

      // if you need to re-use field or drop the same files multiple times
      // this.$refs.form.reset()
    },
    deleteFoto (photo, item) {
      const array = photo.arrayImages
      const arrayName = photo.NameImages

      const index = array.indexOf(item)
      if (index > -1) {
        array.splice(index, 1)
        arrayName.splice(index, 1)
      }
      photo.arrayImages = array
      photo.NameImages = arrayName
    },
    async FirstFoto (photo, item) {
      const array = photo.arrayImages
      const arrayName = photo.NameImages

      const index = array.indexOf(item)
      if (index > -1) {
        await array.unshift(...array.splice(index, 1))
        await arrayName.unshift(...arrayName.splice(index, 1))
      }
      this.photo.arrayImages = array
      this.photo.NameImages = arrayName
    },
    clickRight () {
      if (this.count < this.photo.arrayImages.length - 1) {
        this.count++
      } else {
        this.count = 0
      }
    },
    clickLeft () {
      if (this.count > 0) {
        this.count--
      } else {
        this.count = 0
      }
    },
    async onUpdateForm (event) {
      this.$swal({
        title: 'Іде завантаження...',
        text: '',
        imageUrl: '352.gif' || '~~/assets/352.gif',
        showConfirmButton: false
      })

      // ЗАГРУЗКА ФОТО
      const promises = []
      const promisesName = []
      const File = this.File

      if (File) {
        for (let i = 0; i < File.length; i++) {
          const storageRef = firebase.storage().ref()
          // Загрузить файл и метаданные в объект 'assets/images/***.jpg'

          // Создайте метаданные файла
          const metadata = {
            contentType: 'image/jpeg'
          }
          const nameTime = +new Date() + '.jpg'
          // ПРОВЕРКА ЗАГРУЗКИ ФОТО

          await storageRef.child(`${this.photo.name}/` + nameTime)
          try {
            await storageRef.child(`${this.photo.name}/` + nameTime).put(File[i], metadata)
          } catch (e) {
            console.log(e.message)
          }

          try {
            promises.push(
              await storageRef.child(`${this.photo.name}/` + nameTime).getDownloadURL()
            )
            promisesName.push(
              nameTime
            )
          } catch (e) {
            console.log(e.message)
          }
        }
      }

      const NameImages = await Promise.all(promisesName)
      const URLs = await Promise.all(promises)
      const ArrayOld = await this.photo.arrayImages
      const NameImagesOld = await this.photo.NameImages
      const ArrayFile = [...URLs, ...ArrayOld]
      const ArrayNameImages = [...NameImages, ...NameImagesOld]

      event.preventDefault()
      db.collection('good').doc(this.$route.params.id)
        .update({
          name: this.photo.name,
          description: this.photo.description,
          arrayImages: ArrayFile,
          NameImages: ArrayNameImages
        })
        .then(() => {
          console.log('Category of foto successfully updated!')
          // this.$router.push('/list')
        }).catch((error) => {
          console.log(error)
        })

      const dbRef = await db.collection('good').doc(this.$route.params.id)
      dbRef.get().then((doc) => {
        this.photo = doc.data()
      }).catch((error) => {
        console.log(error)
      })
      try {
        this.$swal('Категорію фото успішно оновлено!!!')
        this.photo.name = ''
        this.photo.description = ''
        this.photo.arrayImages = []
        this.File = []
      } catch (error) {
        console.log(error)
      }
    }
  }
})
